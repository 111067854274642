import React from 'react'
import {connect} from 'react-redux'
import '../../Styles/Overall.css'


import {MonitoringAed} from "../Pages"
import {MonitoringFirstResponder} from "../Pages"

class OverallPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            screen: ['AED', 'FIRSTRESPONDER'],
            status: 0
        }
    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            30000
        )

    }

    componentWillUnmount() {
        clearInterval(this.intervalID)
    }

    tick() {
        if (this.state.status === 1) {
            this.setState({
                status: 2
            })

        }
        else this.setState({
            status: 1
        })
        //console.log(this.state.status)
    }

    render() {
        return (
            <div>
                {
                    this.state.status === 1 ?
                        <MonitoringAed/>
                        :
                        <MonitoringFirstResponder/>
                }

                {/*

                 <div className={this.state.status === 1 ? 'hidden' : 'shown'}>
                    <MonitoringAed/>
                </div>
                <div className={this.state.status === 2 ? 'hidden' : 'shown'}>
                    <MonitoringFirstResponder/>
                </div>

                */}

            </div>
        )
    }
}

function mapStateToProps(state) {
    const {users, authentication} = state
    const {user} = authentication
    return {
        user,
        users
    }
}

const connectedOverallPage = connect(mapStateToProps)(OverallPage)
export {connectedOverallPage as OverallPage}