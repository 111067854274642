import React from 'react';
import {connect} from 'react-redux';
import config from '../../config.js'
import loading from '../../Images/loading.gif';
import {history} from '../../_helpers';

import '../../Styles/MonitoringAed.css';

class MonitoringUserDetail extends React.Component {
    constructor(props) {
        super(props);
        let historyArray = history.location.pathname.split("/")
        let identification = historyArray[historyArray.length - 1]
        this.state = {
            globalForUser: null,
            identification: identification,
            myToken: localStorage.getItem('token')
        };


    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            60000
        );


        this.globalForUser()

    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {

        this.globalForUser()

    }


    globalForUser() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/globalForUser?udid=` + this.state.identification, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {


                return this.setState({globalForUser: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }


    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    render() {


        return (
            <div id="layout-content" className="layout-content-wrapper">

                <div id="mainDashboard">

                    {/* ThisWeek TOP */}
                    <div id="bk1" style={{"width": "100%", "marginTop": "60px"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{"height": "100%"}}>
                                <div style={{"width": "100%"}} className="superviseBlockLeft">
                                    <div>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">priority_high
                                            </i>
                                            Change by this user
                                        </div>
                                    </div>
                                    <div style={{"width": "100%"}}>
                                        <div style={{"width": "100%"}}>

                                            <table className="table table-striped">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Id AED</th>
                                                    <th>Changes</th>
                                                    <th>Date</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                    (this.state.globalForUser && this.state.globalForUser.length === 0) ?

                                                        <tr>
                                                            <td colSpan="5" align="center">No data</td>
                                                        </tr>

                                                        :
                                                        (this.state.globalForUser && this.state.globalForUser.length > 0) ?

                                                            this.state.globalForUser.map(function (object, i) {
                                                                return <PersonRow obj={object} key={i}/>
                                                            })

                                                            :
                                                            <tr>
                                                                <td colSpan="6" align="center"><img alt={""}
                                                                                                    width={'50px'}
                                                                                                    src={loading}
                                                                                                    border="0"/></td>
                                                            </tr>
                                                }


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>


                </div>


            </div>
        );
    }
}

function mapJsonObj(json) {
    let arr = [];
    Object.keys(json).forEach(function (key) {
        if (key !== 'status' && key !== 'sourceInfo') arr.push(key + ' => ' + JSON.stringify(json[key]))
    });
    let i = 1
    return <div>{arr.map(item => <div key={i++}>{item} <br/></div>)}</div>
}

const PersonRow = (props) => {

    let formatedDate = ''
    if (props.obj) {
        let linuxDate = new Date(Date.parse(props.obj.created))

        let newDate = linuxDate.getUTCFullYear() + '/' + (linuxDate.getMonth() + 1) + '/' + linuxDate.getDate()
        let newTime = linuxDate.getHours() + ':' + linuxDate.getMinutes() + ':' + linuxDate.getSeconds()

        formatedDate = newDate + ' - ' + newTime

        //console.log(props.obj.callDate + ' - ' + formatedDate)
    }

    return (
        <tr>

            <td>
                {(props.obj && props.obj.values.status === 'ADD') ?
                    <i className="material-icons" style={{"color": "#059d49"}}>add_box</i>
                    : (props.obj && props.obj.values.status === 'UPD') ?
                        <i className="material-icons" style={{"color": "#059d49"}}>update</i>
                        : <i className="material-icons" style={{"color": "#059d49"}}>check_box</i>
                }
            </td>
            <td>{props.obj.idLocation}</td>
            <td>{
                mapJsonObj(props.obj.values)
            }
            </td>
            <td>{formatedDate}
            </td>
            <td></td>

        </tr>
    );
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedMonitoringUserDetail = connect(mapStateToProps)(MonitoringUserDetail);
export {connectedMonitoringUserDetail as MonitoringUserDetail};