import React from 'react';
import {connect} from 'react-redux';

import config from '../../config.js'

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import GoogleMapReact from 'google-map-react';

import loading from '../../Images/loading.gif';
import iconAlert from '../../Images/picto_pin_alert.png';

import iconDeviceAccept from '../../Images/picto_pin_device_accept.png';
import iconDeviceCall from '../../Images/picto_pin_device_call.png';
import iconDeviceOff from '../../Images/picto_pin_device_off.png';
import iconDeviceRefuse from '../../Images/picto_pin_device_refuse.png';
import iconDeviceFar from '../../Images/picto_pin_device_far.png';

import iconDeviceAcceptNf from '../../Images/picto_pin_device_accept_nf.png';
import iconDeviceCallNf from '../../Images/picto_pin_device_call_nf.png';
import iconDeviceOffNf from '../../Images/picto_pin_device_off_nf.png';
import iconDeviceRefuseNf from '../../Images/picto_pin_device_refuse_nf.png';
import iconDeviceFarNf from '../../Images/picto_pin_device_far_nf.png';

import PropTypes from 'prop-types';

import AlertCounter from '../Components/AlertCounter.jsx'


import '../../Styles/MonitoringFirstResponder.css';

class MonitoringFirstResponderAlerts extends React.Component {
    
    static propTypes = {
        center: PropTypes.array, // @controllable
        zoom: PropTypes.number, // @controllable
        hoverKey: PropTypes.string, // @controllable
        clickKey: PropTypes.string, // @controllable
        onCenterChange: PropTypes.func, // @controllable generated fn
        onZoomChange: PropTypes.func, // @controllable generated fn
        onHoverKeyChange: PropTypes.func, // @controllable generated fn
    };

    constructor(props) {

        var initDate = new Date()
        var todayDate = new Date().toISOString().substr(0, 10)
        var previousDate = new Date(initDate.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)
        console.log( previousDate )

        super(props);
        this.state = {
            activeLine: -1,
            detail: null,
            myToken: localStorage.getItem('token'),
            mapSelectedId: '',
            mapSelectedLon: '',
            mapSelectedLat: '',
            timeout: '',
            initialCenter: {lat: 48.84,lng: 2.23},
            center: {lat: 48.84,lng: 2.23},
            zoom: 15,
            lastSort: {id: '',type: 1},
            dateStart: previousDate,
            dateStop: todayDate,
            markers: [{lat: 45.754100, lng: 3.094837, img_src: iconAlert }],
            siteList: [],
            idSite: 0
        }

       this.sortDetails = this.sortDetails.bind(this)

    }
    
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            60000 * 60 * 12 // 60000 = 1 minutes
        );

        this.tick()
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {

        this.displayForm()
        this.detail()

    }

    displayForm() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),
        }
    
        fetch(`${config.APIURL}/monitoring/firstResponder/citizenResponderAccountList`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()
                return jsonObj
            })
            .then(response => {
                var allSites = [];
                for(var i = 0; i < response.data.sites.length; i++){
                    let data = { 'id': Number(response.data.sites[i]["id"]),  'name': response.data.sites[i]["name"]};
                    allSites.push(data);
                }    
                //console.log(allSites)
                this.setState({siteList: allSites})

            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })
    
    
    }  

    detail() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),
        }

        var formBody = [];
        var encodedKey = encodeURIComponent("dateStart");
        var encodedValue = encodeURIComponent( this.state.dateStart);
        formBody.push(encodedKey + "=" + encodedValue);

        var encodedKey = encodeURIComponent("dateStop");
        var encodedValue = encodeURIComponent( this.state.dateStop);
        formBody.push(encodedKey + "=" + encodedValue);

        var encodedKey = encodeURIComponent("idSite");
        var encodedValue = this.state.idSite;
        formBody.push(encodedKey + "=" + encodedValue);


    formBody = formBody.join("&");
    //console.log(formBody)

        fetch(`${config.APIURL}/monitoring/firstResponder/alerts?` + formBody, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()
                return jsonObj
            })
            .then(response => {
                return this.setState({detail: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    sortDetails(id) {

        function compareDESC(a, b) {
            if (a[id] > b[id])
                return -1;
            if (a[id] < b[id])
                return 1;
            return 0;
        }

        function compareASC(a, b) {
            if (a[id] < b[id])
                return -1;
            if (a[id] > b[id])
                return 1;
            return 0;
        }

        this.state.lastSort.type = this.state.lastSort.type * -1

        if (this.state.lastSort.id === id) {


            if (this.state.lastSort.type === 1) this.setState({detail: this.state.detail.sort(compareDESC)})
            else this.setState({detail: this.state.detail.sort(compareASC)})
        }
        else {

            let array = this.state.detail.sort(compareASC)

            this.setState({detail: array})
            this.state.lastSort.id = id

        }



    };

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value })
    }

    handleSearchClick = (e,id) => {
        e.preventDefault()
        this.setState({mapsLoaded: false})
        this.detail()
    }


    handleClick = (e,id) => {

        const { markers, map,  mapsApi } = this.state;        
        this.setState({ activeLine: id });

        e.preventDefault()
        let myAlert = this.state.detail[id]
        //console.log('this is:', myAlert.id);
     
        var formBody = [];
            var encodedKey = encodeURIComponent("alertId");
            var encodedValue = encodeURIComponent( myAlert.id);
            formBody.push(encodedKey + "=" + encodedValue);
        
        formBody = formBody.join("&");
        //console.log(formBody)

            let settings = {
                method: 'GET',
                headers: new Headers({
                    'Authorization': 'JWT ' + this.state.myToken,
                })
            }
            
         fetch(`${config.APIURL}/monitoring/firstResponder/alertInfo?`+formBody, settings)
             .then(this.handleResponse)
             .then(response => {
                 let jsonObj = response.json()
                 return jsonObj
             })
         .then(response => {

              let myAlertLat  =  Number(myAlert.lat)
              let myAlertLon  =  Number(myAlert.lon)
              
              var allMarkers = [response.data];  
              let data = { 'lat': myAlertLat, 'lng': myAlertLon, 'img_src': iconAlert };
              var alertRadius = myAlert.distance * 1000 
              
              let previousAlert = this.state.alertCircle
              if(previousAlert){
                previousAlert.setMap(null);
              }  

              const myCircle = new mapsApi.Circle   ({
                strokeColor: "#00942a",
                strokeOpacity: 0.7,
                strokeWeight: 1,
                fillColor: "#00942a",
                fillOpacity: 0.10,
                map,
                center: new mapsApi.LatLng( myAlertLat, myAlertLon),
                radius: alertRadius,
              });
    
              myCircle.setMap(map);
              this.setState({alertCircle: myCircle});
            
              allMarkers.push(data);

              for(var i = 0; i < response.data.logs.length; i++){
                let data = { 'lat': Number(response.data.logs[i]["lat"]), 'lng': Number(response.data.logs[i]["lon"]), 'img_src': iconDeviceOff };
                allMarkers.push(data);
              }
              
              for(var i = 0; i < response.data.devices.length; i++){

                let isTrained = false
                if(response.data.devices[i]["trained"] == 1){ isTrained = true }
                var iconVariable = isTrained ? iconDeviceOff : iconDeviceOffNf 
                if(response.data.devices[i]["status"] == "NO" ){ iconVariable = isTrained ? iconDeviceRefuse : iconDeviceRefuseNf }
                if(response.data.devices[i]["status"] == "FAR" ){ iconVariable =  isTrained ? iconDeviceFar : iconDeviceFarNf  }
                if(response.data.devices[i]["status"] == "YES" && response.data.devices[i]["call"] == 1  ){ iconVariable = isTrained ? iconDeviceCall : iconDeviceCallNf   }
                if(response.data.devices[i]["status"] == "YES" && response.data.devices[i]["call"] != 1  ){ iconVariable = isTrained ? iconDeviceAccept : iconDeviceAcceptNf  }

                let data = { 'lat': Number(response.data.devices[i]["lat"]), 'lng': Number(response.data.devices[i]["lon"]), 'img_src': iconVariable };
                allMarkers.push(data);
              }

              this.setState({center: {lat: myAlertLat,lng: myAlertLon}})
              this.setState({markers: allMarkers})

         })
         .catch(error => {
             console.log(error.toString())
             return this.setState({error})
         })

    }

    handleSort(e, id) {
        e.preventDefault()
        this.sortDetails(id)
    };




    _onBoundsChange = (center, zoom, bounds, marginBounds) => {
        console.log("_onBoundsChange")
        console.log(zoom)
        console.log(bounds)
     }
     
 
     _onChildClick = (key, childProps) => {
 
         //console.log(this.props.onCenterChange)
         console.log("_onChildClick ")
         //console.log(key)
         //console.log(childProps)
         
         //const index = this.props.markers.findIndex(m => m.get('id') === markerId);
         //if (this.props.onChildClick) {
         //  this.props.onChildClick(key);
         //}
       }
     
       _onChildMouseEnter = (key, childProps) => {
         console.log("_onChildMouseEnter")
         //const markerId = childProps.marker.get('id');
         //const index = this.props.markers.findIndex(m => m.get('id') === markerId);
         //if (this.props.onMarkerHover) {
         //  this.props.onMarkerHover(index);
         //}
       }
     
       _onChildMouseLeave = (/* key, childProps */) => {
         console.log("_onChildMouseLeave")
         //if (this.props.onMarkerHover) {
         //  this.props.onMarkerHover(-1);
         //}
       }
 
       _createMapOptions = (maps) => {
         return {
           panControl: false,
           mapTypeControl: false,
           scrollwheel: false
         }
       }
 
 
     apiHasLoaded = ((map, mapsApi) => {
         
         this.setState({
           mapsLoaded: true,
           map,
           mapsApi,
           geoCoderService: new mapsApi.Geocoder(),
         });
            
     });




    render() {
        
        const AnyReactComponent = ({ img_src }) => (
            <div><img src={img_src} style={{width: '35px', height: '35px'}}/></div>
        );
          
        return (
            
            <div id="layout-content" className="layout-content-wrapper">
                                   <div style={{ padding: '70px'}} > 
                        <Grid container justify="center" spacing="3"> 
                             <Grid item> 
                                <TextField
                                    id="dateStart"
                                    name="dateStart"
                                    label="Start Date"
                                    type="date"
                                    onChange={(e) => this.handleChange(e)}
                                    defaultValue={this.state.dateStart}
                                    className={useStyles.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </Grid> 
                            <Grid item>        
                                <TextField
                                    id="dateStop"
                                    name="dateStop"
                                    label="End Date"
                                    type="date"
                                    defaultValue={this.state.dateStop}
                                    onChange={(e) => this.handleChange(e)}
                                    className={useStyles.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                             </Grid>  
                             <Grid item> 
                                <InputLabel shrink htmlFor="age-native-label-placeholder">
                                        Account
                                </InputLabel>       
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="idSite"
                                    name="idSite"
                                    open={this.openSelect}
                                    onClose={this.handleClose}
                                    onOpen={this.handleOpen}
                                    value={this.state.idSite}
                                    onChange={(e) => this.handleChange(e)}
                                    >
                                    {this.state.siteList.map((obj, i) =>
                                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                                    )}

                                </Select>
                             </Grid>  
                             <Grid item>         
                                <Button variant="contained" color="primary" onClick={this.handleSearchClick} >  Validate  </Button>
                             </Grid>   
                         </Grid>   
                </div>
            <div style={{width: '100%', height: '400px', position: 'fixed', top: '120px'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCLRdKbTQ7uVOV4KfW2G46RCbEo4mUmp3c' }}
                    center={this.state.center}
                    zoom={this.state.zoom}
                    onChange={this._onBoundsChange}
                    onChildClick={this._onChildClick}
                    onChildMouseEnter={this._onChildMouseEnter}
                    onChildMouseLeave={this._onChildMouseLeave}

                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    options={this._createMapOptions}
                >
                {this.state.markers.map((marker, i) =>{
                    return(
                        <AnyReactComponent 
                            lat={marker.lat} 
                            lng={marker.lng} 
                            img_src={marker.img_src}
                        />
                        )
                })}
            </GoogleMapReact>
            </div>
                 <div id="mainDashboard">
                    {/* ThisWeek TOP */}
                    <div id="bk1" style={{"width": "100%","marginTop": "340px"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{"height": "100%"}}>
                                <div style={{"width": "100%"}} className="superviseBlockLeft">
                                    <div>
                                        <div className="superviseTitle">
                                                Citizen Responder Alerts
                                        </div>
                                    </div>
                                    <div style={{"width": "100%"  }}>
                                        <div className="table-responsive" style={{"width": "100%"}}>

                                            <table className="table table-striped table-hover table-condensed">
                                                <thead>
                                                <tr>
                                                    <th>
                                                        <a href="#" onClick={(e) => this.handleSort(e, 'id')}>
                                                            idAlert
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'siteName')}>
                                                            Site
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'cdate')}>
                                                            Date
                                                        </a>
                                                    </th>
                                                    <th> Address </th>
                                                    <th> # Ok </th> 
                                                    <th> # Dispatch </th>                                                     
                                                    <th> # Total </th>
                                                    <th> T (min/s) </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    (this.state.detail && this.state.detail.length === 0) ?

                                                        <tr>
                                                            <td colSpan="8" align="center">No data</td>
                                                        </tr>

                                                        :
                                                        (this.state.detail && this.state.detail.length > 0) ?
                                                            this.state.detail.map((obj, i) => ( 

                                                                <tr className={this.state.activeLine == i  ? 'selectedLine': null}  key={obj.id} onClick={(e) => this.handleClick(e, i)}>
                                                                    <td>{obj.id}</td>
                                                                    <td>{obj.siteName}</td>
                                                                    <td>{formatedDate(obj.cdate)}</td>
                                                                    <td>{obj.address}</td>
                                                                    <AlertCounter idAlert={obj.id} />
                                                                    <td>{obj.totalDevices}</td>
                                                                    <td>{obj.firstAcceptDevice}</td>
                                                                </tr>

                                                            ))

                                                            :
                                                            <tr>
                                                                <td colSpan="8" align="center"><img alt={""}
                                                                                                    width={'50px'}
                                                                                                    src={loading}
                                                                                                    border="0"/></td>
                                                            </tr>
                                                }


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>


                </div>


            </div>
        );
    }
}

function formatedDate(myDate){
   
    let clearString = function (string) {
        if (string.toString().length < 2) string = '0' + string
        return string
    }
    let formatedDate = ''
    
    let linuxDate = new Date(Date.parse(myDate))
    let newDate = clearString(linuxDate.getUTCFullYear()) + '/' + clearString((linuxDate.getMonth() + 1)) + '/' + clearString(linuxDate.getDate())
    let newTime = clearString(linuxDate.getHours()) + ':' + clearString(linuxDate.getMinutes()) + ':' + clearString(linuxDate.getSeconds())

    formatedDate = newDate + ' - ' + newTime
   
    return  formatedDate  
}


function counterAlert(idAlert){
   
    return  idAlert 

}



function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}


const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));
  

const connectedHomePage = connect(mapStateToProps)(MonitoringFirstResponderAlerts);
export {connectedHomePage as MonitoringFirstResponderAlerts};