import React from 'react';
import {connect} from 'react-redux';
import config from '../../config.js'

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import loading from '../../Images/loading.gif';
import '../../Styles/MonitoringFirstResponder.css';

class MonitoringFirstResponderAnalytics extends React.Component {
    constructor(props) {

        var initDate = new Date()
        var todayDate = new Date().toISOString().substr(0, 10)
        var previousDate = new Date(initDate.setMonth(initDate.getMonth()-1)).toISOString().substr(0, 10);

        super(props);
        this.state = {
            myToken: localStorage.getItem('token'),
            dateStart: previousDate,
            dateStop: todayDate,
            analyticsData: {}
        }

    }

    componentDidMount() {
        this.display()
    }

    componentWillUnmount() {
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value })
    }

    handleClick = (e,id) => {
        e.preventDefault()
        this.display()
    }

    display() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),
        }

        var formBody = [];
            var encodedKey = encodeURIComponent("dateStart");
            var encodedValue = encodeURIComponent( this.state.dateStart);
            formBody.push(encodedKey + "=" + encodedValue);

            var encodedKey = encodeURIComponent("dateStop");
            var encodedValue = encodeURIComponent( this.state.dateStop + 'T23:59:59' );
            formBody.push(encodedKey + "=" + encodedValue);

            formBody = formBody.join("&");

        console.log(formBody)

        fetch(`${config.APIURL}/monitoring/firstResponder/analytics?`+formBody, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()
                return jsonObj
            })
            .then(response => {
                console.log(response)
                this.setState({analyticsData: response.data})
                
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    render() {

        return (
            <div id="layout-content" className="layout-content-wrapper">
                <div style={{ padding: '70px'}} > 
                        <Grid container justify="center" spacing="3"> 
                             <Grid item> 
                                <TextField
                                    id="dateStart"
                                    name="dateStart"
                                    label="Start Date"
                                    type="date"
                                    onChange={(e) => this.handleChange(e)}
                                    defaultValue={this.state.dateStart}
                                    className={useStyles.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </Grid> 
                            <Grid item>        
                                <TextField
                                    id="dateStop"
                                    name="dateStop"
                                    label="End Date"
                                    type="date"
                                    defaultValue={this.state.dateStop}
                                    onChange={(e) => this.handleChange(e)}
                                    className={useStyles.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                             </Grid>  
                             <Grid item>         
                                <Button variant="contained" color="primary" onClick={this.handleClick} >  Validate  </Button>
                             </Grid>   
                         </Grid>   
                </div>
                <div > 
                        <Grid container justify="center" spacing="3"> 
                            <Grid item> 
                                    <p className="analyticsType">&nbsp;</p>
                                    <p className="analyticsCategory">Primo</p>
                                    <p className="analyticsCategory">Primo Web</p>
                                    <p className="analyticsCategory">Existant</p>
                                    <p className="analyticsCategory">Total</p>
                            </Grid> 
                             <Grid item> 
                                    <p className="analyticsType">Inscription web</p>
                                    <p className="analyticsData">...</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWeb}</p>
                                    <p className="analyticsData">...</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWebTotal}</p>
                            </Grid> 
                            <Grid item>        
                                    <p className="analyticsType">SMS envoyé</p>
                                    <p className="analyticsData">...</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWebSMS} <span className="smsTotal">#{this.state.analyticsData.nbWebSMSTotal}</span></p>
                                    <p className="analyticsData">...</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbSMS} <span className="smsTotal">#{this.state.analyticsData.nbSMSTotal}</span></p>
                             </Grid>  
                             <Grid item>         
                                    <p className="analyticsType">Code recu</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbSMSValidFirst}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWebSMSValid}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbSMSValidExist}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbSMSValidTotal}</p>
                             </Grid>   
                             <Grid item>         
                                    <p className="analyticsType">Inscription finalisée</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbUpdtFirst}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWebUpdt}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbUpdtExist}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbUpdtTotal}</p>
                             </Grid>
                             <Grid item>         
                                    <p className="analyticsType">Compte validé</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbValidFirst}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWebValid}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbValidExist}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbValidTotal}</p>
                             </Grid> 
                             <Grid item>         
                                    <p className="analyticsType">Compte refusé</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbRefuseFirst}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbWebRefuse}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbRefuseExist}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbRefuseTotal}</p>
                             </Grid>       
                         </Grid>   
                </div>
                <br/><br/>
                <div> 
                         <Grid container justify="center" spacing="3"> 
                            <Grid item> 
                                    <p className="analyticsType">&nbsp;</p>
                                    <p className="analyticsCategory">Non formé</p>
                                    <p className="analyticsCategory">Formé</p>
                                    <p className="analyticsCategory">Formé sans diplôme</p>
                            </Grid> 
                             <Grid item> 
                                    <p className="analyticsType">Inscription finalisée</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbUpdt0}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbUpdt1}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbUpdt2}</p>
                            </Grid> 
                            <Grid item>        
                                    <p className="analyticsType">Compte validé</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbValid0}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbValid1}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbValid2}</p>
                             </Grid>  
                             <Grid item>         
                                    <p className="analyticsType">Compte refusé</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbRefuse0}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbRefuse1}</p>
                                    <p className="analyticsData">{this.state.analyticsData.nbRefuse2}</p>
                             </Grid>         
                         </Grid>  
                </div> 
                <br/><br/>        
                <div > 
                    <Grid container justify="center" spacing="3"> 
                        <Grid item>     
                            <p >Comptes supprimés : {this.state.analyticsData.nbDeleted}</p>   
                        </Grid>    
                   </Grid>                
                </div>                          
            </div>
        );
    }
}


function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));

const connectedHomePage = connect(mapStateToProps)(MonitoringFirstResponderAnalytics);
export {connectedHomePage as MonitoringFirstResponderAnalytics};