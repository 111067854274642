import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


class HomePage extends React.Component {
    componentDidMount() {
    }

    render() {
        return (
            <div className="container">
                 <h1>User info {this.state}</h1>
                <h3>Monitoring : </h3>
                <p>
                    <Link to="/overall">Overall Monitoring</Link>
                    <br/>
                    <Link to="/monitoringAed">AED Monitoring</Link>
                    <br/>
                    <Link to="/monitoringFirstResponder">Citizen Responder Monitoring</Link>
                    <br/>
                    <Link to="/monitoringFirstResponderDetail">Citizen Responder Detail</Link>
                    <br/>
                    <Link to="/monitoringFirstResponderByState">Citizen Responder By State</Link>
                    <br/>
                    <Link to="/monitoringFirstResponderAlerts">Citizen Responder Alerts</Link>
                    <br/>
                    <Link to="/monitoringFirstResponderAlertsMap">Citizen Responder Alerts Map</Link>
                    <br/>
                    <Link to="/monitoringFirstResponderAnalytics">Citizen Responder Analytics</Link>
                    <br/>
                    <Link to="/monitoringAppTest">App Test Monitoring</Link>
                    <br/>
                
                    <Link to="/login">Logout</Link>
                </p>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };