import React from 'react';
import {connect} from 'react-redux';
import Chart from "react-google-charts";
import config from '../../config.js'
import MonitoringTools from '../../Tools/MonitoringTools'

import loading from '../../Images/loading.gif';
import '../../Styles/MonitoringFirstResponder.css';

class MonitoringFirstResponder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            registered: null,
            engaged: null,
            myToken: localStorage.getItem('token'),
            timeout: ''
        }


    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            60000
        );

        this.tick()
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.AlertMonitoring()
        this.RegisteredMonitoring()
        this.engaged()
    }


    AlertMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/alert`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {
                let dataEx = [["dt", "Nb", {role: "style"}]]
                response.data.graph.forEach(function (element) {
                    dataEx.push([element.dt, element.ct, "color: blue"])
                });

                response.data.graph = dataEx
                return this.setState({alert: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    RegisteredMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/registered`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {
                let dataEx = [["dt", "Nb", {role: "style"}]]
                response.data.graph.forEach(function (element) {
                    dataEx.push([element.dt, element.ct, "color: blue"])
                });

                response.data.graph = dataEx
                return this.setState({registered: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    engaged() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/engaged`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {


                return this.setState({engaged: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    render() {
        let options = {
            colors: "#ea8f05",
            backgroundColor: {fill: 'transparent'},
            title: '',
            width: '100%',
            height: '100%',
            //colors: ['#61dafb;'],
            legend: {position: 'none'},
            axes: {
                x: {
                    0: {side: 'top', label: ''} // Top x-axis.
                }
            },
            chart: {subtitle: ''},
            bar: {
                groupWidth: "90%"
            }
        };

        return (
            <div id="layout-content" className="layout-content-wrapper">

                <div id="mainDashboard">


                    {/* Added */}
                    <div style={{"width": "100%", "paddingTop": "60px"}} ></div>
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise">
                                <div className="superviseBlockLeft">
                                    <div style={{"height": "40%"}}>
                                        <div className="superviseTitle">
                                            <i className="material-icons">add_box</i>First Responders registration
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock">{this.state.registered ? MonitoringTools.cleanNumber(this.state.registered.today) : null}</div>
                                    </div>
                                    <div style={{"height": "60%"}}>
                                        <div id="graph1" className="superviseGraph">

                                            {
                                                (this.state.registered && this.state.registered.graph && this.state.registered.graph.length > 1) ?
                                                    <Chart chartType="Bar"
                                                           data={this.state.registered.graph}
                                                           options={options}
                                                    /> :
                                                    <img width={'50px'} src={loading} border="0" alt={""}/>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="superviseBlockRight">
                                    <div className="superviseBlockRightTop">
                                        <div className="superviseTitleRightBlock"> Total (current month)</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.registered ? MonitoringTools.cleanNumber(this.state.registered.month) : null}</div>
                                    </div>
                                    <div className="superviseBlockRightBottom">
                                        <div className="superviseTitleRightBlock"> Total</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.registered ? MonitoringTools.cleanNumber(this.state.registered.global) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* Validated */}
                    <div id="bk2" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise">
                                <div className="superviseBlockLeft">
                                    <div style={{"height": "40%"}}>
                                        <div className="superviseTitle">
                                            <i className="material-icons">add_alert</i>
                                            First Responders
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock">{this.state.alert ? this.state.alert.today : null}</div>
                                    </div>
                                    <div style={{"height": "60%"}}>
                                        <div id="graph1" className="superviseGraph">

                                            {
                                                (this.state.alert && this.state.alert.graph && this.state.alert.graph.length > 1) ?
                                                    <Chart chartType="Bar"
                                                           data={this.state.alert.graph}
                                                           options={options}
                                                    /> :
                                                    <img alt={""} width={'50px'} src={loading} border="0"/>
                                            }


                                        </div>
                                    </div>
                                </div>
                                <div className="superviseBlockRight">
                                    <div className="superviseBlockRightTop">
                                        <div className="superviseTitleRightBlock"> Total (current month)</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.alert ? this.state.alert.month : null}</div>
                                    </div>
                                    <div className="superviseBlockRightBottom">
                                        <div className="superviseTitleRightBlock"> Total</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.alert ? MonitoringTools.cleanNumber(this.state.alert.global) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    {/* ThisWeek TOP */}
                    <div id="bk1" style={{"width": "100%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{"height": "100%"}}>
                                <div style={{"width": "100%"}} className="superviseBlockLeft">
                                    <div>
                                        <div className="superviseTitle">
                                            <i className="material-icons">priority_high
                                            </i>
                                            Last engagement (7 days)
                                        </div>
                                    </div>
                                    <div style={{"width": "100%"}}>
                                        <div className="table-responsive" style={{"width": "100%"}}>

                                            <table className="table table-striped table-hover table-condensed">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Date/Time</th>
                                                    <th>Name</th>
                                                    <th>Skill</th>
                                                    <th>Address</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                    (this.state.engaged && this.state.engaged.length === 0) ?

                                                        <tr>
                                                            <td colSpan="5" align="center">No data</td>
                                                        </tr>

                                                        :
                                                        (this.state.engaged && this.state.engaged.length > 0) ?

                                                            this.state.engaged.map(function (object, i) {
                                                                return <PersonRow obj={object} key={i}/>
                                                            })

                                                            :
                                                            <tr>
                                                                <td colSpan="5" align="center"><img alt={""}
                                                                                                    width={'50px'}
                                                                                                    src={loading}
                                                                                                    border="0"/></td>
                                                            </tr>
                                                }


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>


                </div>


            </div>
        );
    }
}

const PersonRow = (props) => {

    let clearString = function (string) {
        if (string.toString().length < 2) string = '0' + string
        return string
    }

    //console.log(props.obj)
    let formatedDate = ''
    if (props.obj) {
        let linuxDate = new Date(Date.parse(props.obj.callDate))

        let durationHours = (new Date() - linuxDate) / 1000 / 60 / 60
        if (durationHours > 1) props.obj.close = 1
        //console.log(durationHours)

        let newDate = clearString(linuxDate.getUTCFullYear()) + '/' + clearString((linuxDate.getMonth() + 1)) + '/' + clearString(linuxDate.getDate())
        let newTime = clearString(linuxDate.getHours()) + ':' + clearString(linuxDate.getMinutes()) + ':' + clearString(linuxDate.getSeconds())

        formatedDate = newDate + ' - ' + newTime

        //console.log(props.obj.callDate + ' - ' + formatedDate)
    }

    //console.log(props.keyObj)
    return (
        <tr className={(props.obj && props.obj.close === 0) ? "success" : ""}>

            <td>{

                /*
                    (props.obj && props.obj.close === 1) ?
                        <i className="material-icons" style={{"color": "#ea8f05"}}>cancel</i>
                        : (props.obj && props.obj.onsiteDate !== "0000-00-00 00:00:00") ?
                        <i className="material-icons" style={{"color": "#ea8f05"}}>place</i>
                        : (props.obj && props.obj.onmywayDate !== "0000-00-00 00:00:00") ?
                            <i className="material-icons" style={{"color": "#ea8f05"}}>navigation</i>
                            : null

                            */
                (props.obj && props.obj.onsiteDate !== "0000-00-00 00:00:00") ?
                    <i className="material-icons" style={{"color": "#ea8f05"}}>place</i>
                    : null


            }
            </td>
            <td>{formatedDate}</td>
            <td>{(props.obj.firstname && props.obj.firstname.length !== 0) ? props.obj.firstname : '-'} {(props.obj.lastname && props.obj.lastname.length !== 0) ? props.obj.lastname : '-'}</td>
            <td>{props.obj.skill}</td>
            <td>{props.obj.address}</td>
        </tr>
    );
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(MonitoringFirstResponder);
export {connectedHomePage as MonitoringFirstResponder};