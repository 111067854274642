import {combineReducers} from 'redux';

import {authentication} from './authentication.reducer';
import {alertMessage} from './alert.reducer';

const rootReducer = combineReducers({
    authentication,
    alertMessage
});

export default rootReducer;