import React from 'react';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';

import config from '../../config.js'

import GoogleMapReact from 'google-map-react';


import loading from '../../Images/loading.gif';
import iconAlert from '../../Images/redDot2.png';


import '../../Styles/MonitoringFirstResponder.css';

class MonitoringFirstResponderAlertsMap extends React.Component {

    static propTypes = {
        center: PropTypes.array, // @controllable
        zoom: PropTypes.number, // @controllable
        hoverKey: PropTypes.string, // @controllable
        clickKey: PropTypes.string, // @controllable
        onCenterChange: PropTypes.func, // @controllable generated fn
        onZoomChange: PropTypes.func, // @controllable generated fn
        onHoverKeyChange: PropTypes.func, // @controllable generated fn
    };

    constructor(props) {

        var initDate = new Date()
        var todayDate = new Date().toISOString().substr(0, 10)
        var previousDate = new Date(initDate.getTime() - (7 * 24 * 60 * 60 * 1000)).toISOString().substr(0, 10)

        super(props);
        this.state = {
            openSelect: false,
            mapsLoaded : false,
            detail: null,
            myToken: localStorage.getItem('token'),
            dateStart: previousDate,
            dateStop: todayDate,
            timeout: '',
            center: {lat: 48.84,lng: 2.23},
            zoom: 12,
            lastSort: {id: '',type: 1},
            markers: [],
            Options: '',
            siteList: [],
            idSite: 0
        }

        this.displayForm()
        this.display()
        
    }


    displayForm() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),
        }
    
        fetch(`${config.APIURL}/monitoring/firstResponder/citizenResponderAccountList`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()
                return jsonObj
            })
            .then(response => {
                var allSites = [];
                for(var i = 0; i < response.data.sites.length; i++){
                    let data = { 'id': Number(response.data.sites[i]["id"]),  'name': response.data.sites[i]["name"]};
                    allSites.push(data);
                }    
                //console.log(allSites)
                this.setState({siteList: allSites})

            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })
    
    
    }    

    display() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),
        }

        var formBody = [];
            var encodedKey = encodeURIComponent("dateStart");
            var encodedValue = encodeURIComponent( this.state.dateStart);
            formBody.push(encodedKey + "=" + encodedValue);

            var encodedKey = encodeURIComponent("dateStop");
            var encodedValue = encodeURIComponent( this.state.dateStop);
            formBody.push(encodedKey + "=" + encodedValue);

            var encodedKey = encodeURIComponent("idSite");
            var encodedValue = this.state.idSite;
            formBody.push(encodedKey + "=" + encodedValue);


        formBody = formBody.join("&");
        console.log(formBody)

        fetch(`${config.APIURL}/monitoring/firstResponder/alertsByPeriod?`+formBody, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()
                return jsonObj
            })
            .then(response => {
                var allMarkers = [];
                for(var i = 0; i < response.data.length; i++){
                    let data = { 'lat': Number(response.data[i]["lat"]), 'lng': Number(response.data[i]["lon"]),  'address': response.data[i]["address"]  ,  'cdate': response.data[i]["cdate"] ,  'siteName': response.data[i]["siteName"] ,  'totalAccept': response.data[i]["totalAccept"] ,  'totalCall': response.data[i]["totalCall"] ,  'totalDevices': response.data[i]["totalDevices"] };
                    allMarkers.push(data);
                }    
                
                this.setState({center: {lat: 48.84,lng: 2.23}})
                this.setState({markers: allMarkers})
                this.setState({mapsLoaded: true})

            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    handleClick = (e,id) => {
        e.preventDefault()
        this.setState({mapsLoaded: false})
        this.display()
    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value })
    }

    handleClose () {
        //this.setState({ openSelect : false })
    }

    handleOpen () {
        //this.setState({ openSelect: true })
    }


    _onBoundsChange = (center, zoom, bounds, marginBounds) => {
       console.log("_onBoundsChange")
       console.log(zoom)
    }
    

    _onChildClick = (key, childProps) => {

        //console.log(this.props.onCenterChange)
        console.log("_onChildClick ")
        //console.log(key)
        //console.log(childProps)
        
        //const index = this.props.markers.findIndex(m => m.get('id') === markerId);
        //if (this.props.onChildClick) {
        //  this.props.onChildClick(key);
        //}
      }
    
      _onChildMouseEnter = (key, childProps) => {
        console.log("_onChildMouseEnter")
        //const markerId = childProps.marker.get('id');
        //const index = this.props.markers.findIndex(m => m.get('id') === markerId);
        //if (this.props.onMarkerHover) {
        //  this.props.onMarkerHover(index);
        //}
      }
    
      _onChildMouseLeave = (/* key, childProps */) => {
        console.log("_onChildMouseLeave")
        //if (this.props.onMarkerHover) {
        //  this.props.onMarkerHover(-1);
        //}
      }

      _createMapOptions = (maps) => {
        return {
          panControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
        }
      }


    apiHasLoaded = ((map, mapsApi) => {
        
        this.setState({
          mapsLoaded: true,
          map,
          mapsApi,
          geoCoderService: new mapsApi.Geocoder(),
        });
           
          var markers = [];

          var infowindow = new mapsApi.InfoWindow({
            // content: contentString
          });

          var imageMarker = {
            url: iconAlert,
            size: new mapsApi.Size(10, 10), 
            origin: new mapsApi.Point(0, 0),
            anchor: new mapsApi.Point(0, 10),
            scaledSize: new mapsApi.Size(10, 10)
          };

        if(this.state.markers.length > 0) { 

           var bounds = new mapsApi.LatLngBounds();
         
           this.state.markers.forEach((markerItems) => {
               
            console.log(" boucle markers ")   

               var marker = new mapsApi.Marker({
                     position: new mapsApi.LatLng(
                        markerItems.lat,
                        markerItems.lng,
                     ),
                     map,
                     icon: imageMarker,
                     anchorPoint: new mapsApi.Point(0, -10),
                     title: markerItems.address
                });

                marker.addListener('click', function() {
                                        
                    var contentString = '<div id="content">'+
                    '<div id="siteNotice">'+
                    '</div>'+
                    '<h1 id="firstHeading" style="font-size:20px;">'+markerItems.address+'</h1>'+
                    '<div id="bodyContent">'+
                    '<p><i>Date: '+formatedDate(markerItems.cdate)+'</i></p>'+
                    '<p><b>By: '+markerItems.siteName+'</b></p>'+
                    '<p># Ok: '+markerItems.totalAccept+'</p>'+
                    '<p># Dispatch: '+markerItems.totalCall+'</p>'+
                    '<p># Total: '+markerItems.totalDevices+'</p>'+
                    '</div>'+
                    '</div>';
                    
                    infowindow.setContent(contentString);
                    infowindow.open(map, marker);
                });

                markers.push(marker);
                
                bounds.extend(new mapsApi.LatLng(
                    markerItems.lat,
                    markerItems.lng,
                ));
            });
  
            // const bounds = getMapBounds(map, mapsApi, places);
            // Fit map to bounds
            map.fitBounds(bounds);
        
        }
        // Bind the resize listener
        //bindResizeListener(map, mapsApi, bounds);
    });

    render() {

        const isMapsLoaded = this.state.mapsLoaded;
    
        return (
            
            <div id="layout-content" className="layout-content-wrapper">
                    <div style={{ padding: '70px'}} > 
                        <Grid container justify="center" spacing="3"> 
                             <Grid item> 
                                <TextField
                                    id="dateStart"
                                    name="dateStart"
                                    label="Start Date"
                                    type="date"
                                    onChange={(e) => this.handleChange(e)}
                                    defaultValue={this.state.dateStart}
                                    className={useStyles.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                            </Grid> 
                            <Grid item>        
                                <TextField
                                    id="dateStop"
                                    name="dateStop"
                                    label="End Date"
                                    type="date"
                                    defaultValue={this.state.dateStop}
                                    onChange={(e) => this.handleChange(e)}
                                    className={useStyles.textField}
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                />
                             </Grid>  
                             <Grid item> 
                                <InputLabel shrink htmlFor="age-native-label-placeholder">
                                        Account
                                </InputLabel>       
                                <Select
                                    labelId="demo-controlled-open-select-label"
                                    id="idSite"
                                    name="idSite"
                                    open={this.openSelect}
                                    onClose={this.handleClose}
                                    onOpen={this.handleOpen}
                                    value={this.state.idSite}
                                    onChange={(e) => this.handleChange(e)}
                                    >
                                    {this.state.siteList.map((obj, i) =>
                                        <MenuItem value={obj.id}>{obj.name}</MenuItem>
                                    )}

                                </Select>
                             </Grid>  
                             <Grid item>         
                                <Button variant="contained" color="primary" onClick={this.handleClick} >  Validate  </Button>
                             </Grid>   
                         </Grid>   
                </div>
                <div style={{width: '100%', height: '90%', position: 'fixed', top: '120px'}}>
                     {isMapsLoaded ? (
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyCLRdKbTQ7uVOV4KfW2G46RCbEo4mUmp3c' }}
                            center={this.state.center}
                            zoom={this.state.zoom}
                            onBoundsChange={this._onBoundsChange}
                            onChildClick={this._onChildClick}
                            onChildMouseEnter={this._onChildMouseEnter}
                            onChildMouseLeave={this._onChildMouseLeave}
                    
                            yesIWantToUseGoogleMapApiInternals={true}
                            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                            options={this._createMapOptions}

                        >
                        </GoogleMapReact>
                    ) : (
                        <Grid container justify="center" spacing="" > 
                            <Grid item>  
                             <p  style={{ marginTop : '70px'}}> <img width={'50px'} src={loading} border="0" alt={""}/>      
 Waiting.... </p>
                            </Grid>   
                        </Grid>        
                    )}
                </div>
            </div>
        );
    }



}

function formatedDate(myDate){
   
    let clearString = function (string) {
        if (string.toString().length < 2) string = '0' + string
        return string
    }
    let formatedDate = ''
    
    let linuxDate = new Date(Date.parse(myDate))
    let newDate = clearString(linuxDate.getUTCFullYear()) + '/' + clearString((linuxDate.getMonth() + 1)) + '/' + clearString(linuxDate.getDate())
    let newTime = clearString(linuxDate.getHours()) + ':' + clearString(linuxDate.getMinutes()) + ':' + clearString(linuxDate.getSeconds())

    formatedDate = newDate + ' - ' + newTime
   
    return  formatedDate  
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 400,
    },
  }));

const connectedHomePage = connect(mapStateToProps)(MonitoringFirstResponderAlertsMap);
export {connectedHomePage as MonitoringFirstResponderAlertsMap};