import React from 'react';
import {connect} from 'react-redux';
import Chart from "react-google-charts";
import config from '../../config.js'
import MonitoringTools from '../../Tools/MonitoringTools'
import loading from '../../Images/loading.gif';

import {history} from '../../_helpers';

import '../../Styles/MonitoringAed.css';

class MonitoringAed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: null,
            validate: null,
            info: null,
            report: null,
            thisWeekUpdateByUser: null,
            globalUpdateByUser: null,
            myToken: localStorage.getItem('token')
        };

    }

    componentDidMount() {
        let timeout = 5 // minutes
        this.intervalID = setInterval(
            () => this.tick(),
            60000 * timeout
        );

        this.UpdateMonitoring()
        this.ValidateMonitoring()
        this.InfoMonitoring()
        this.ReportMonitoring()
        this.ThisWeekUpdateMonitoring()
        this.GlobalkUpdateMonitoring()

    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.UpdateMonitoring()
        this.ValidateMonitoring()
        this.InfoMonitoring()
        this.ReportMonitoring()
        this.ThisWeekUpdateMonitoring()
        this.GlobalkUpdateMonitoring()

    }

    UpdateMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/update`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {

                let dataEx = [["dt", "Nb", {role: "style"}]]
                response.data.graph.forEach(function (element) {
                    dataEx.push([element.dt, element.ct, "color: blue"])
                });

                response.data.graph = dataEx

                return this.setState({update: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    ValidateMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/validate`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {
                let dataEx = [["dt", "Nb", {role: "style"}]]
                response.data.graph.forEach(function (element) {
                    dataEx.push([element.dt, element.ct, "color: blue"])
                });

                response.data.graph = dataEx
                return this.setState({validate: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    InfoMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/info`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {
                let dataEx = [["dt", "Nb", {role: "style"}]]
                response.data.graph.forEach(function (element) {
                    dataEx.push([element.dt, element.ct, "color: blue"])
                });

                response.data.graph = dataEx
                return this.setState({info: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    ReportMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/report`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {
                let dataEx = [["dt", "Nb", {role: "style"}]]
                response.data.graph.forEach(function (element) {
                    dataEx.push([element.dt, element.ct, "color: blue"])
                });

                response.data.graph = dataEx
                return this.setState({report: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    ThisWeekUpdateMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/thisWeekUpdateByUser`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {


                return this.setState({thisWeekUpdateByUser: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    GlobalkUpdateMonitoring() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/aed/globalTopUser`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {


                return this.setState({globalUpdateByUser: response.data})
            })
            .catch(error => {
                console.log('Catch')
                console.log(error.toString())
                return this.setState({error})
            })

    }


    handleResponse(response) {
        //if (response.data)console.log(response.json().data.message)
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    render() {
        let options = {
            colors: "#059d49",
            backgroundColor: {fill: 'transparent'},
            title: '',
            width: '100%',
            height: '100%',
            //colors: ['#61dafb;'],
            legend: {position: 'none'},
            axes: {
                x: {
                    0: {side: 'top', label: ''} // Top x-axis.
                }
            },
            chart: {subtitle: ''},
            bar: {
                groupWidth: "90%"
            }
        };

        return (
            <div id="layout-content" className="layout-content-wrapper">

                <div id="mainDashboard">


                    {/* Added */}
                    <div style={{"width": "100%", "paddingTop": "60px"}} ></div>
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise">
                                <div className="superviseBlockLeft">
                                    <div style={{"height": "40%"}}>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">add_box</i>New AED
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock">{this.state.info ? MonitoringTools.cleanNumber(this.state.info.today) : null}</div>
                                    </div>
                                    <div style={{"height": "60%"}}>
                                        <div id="graph1" className="superviseGraph">

                                            {
                                                (this.state.info && this.state.info.graph && this.state.info.graph.length > 1) ?
                                                    <Chart chartType="Bar"
                                                           data={this.state.info.graph}
                                                           options={options}
                                                    /> :
                                                    <img alt={""} width={'50px'} src={loading} border="0"/>}

                                        </div>
                                    </div>

                                </div>
                                <div className="superviseBlockRight">
                                    <div className="superviseBlockRightTop">
                                        <div className="superviseTitleRightBlock"> Total (current month)</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.info ? MonitoringTools.cleanNumber(this.state.info.month) : null}</div>
                                    </div>
                                    <div className="superviseBlockRightBottom">
                                        <div className="superviseTitleRightBlock"> Total</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.info ? MonitoringTools.cleanNumber(this.state.info.global) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* Updated */}
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise">
                                <div className="superviseBlockLeft">
                                    <div style={{"height": "40%"}}>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">update</i>
                                            AED Updated
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock">{this.state.update ? MonitoringTools.cleanNumber(this.state.update.today) : null}</div>
                                    </div>
                                    <div style={{"height": "60%"}}>
                                        <div id="graph1" className="superviseGraph">
                                            {
                                                (this.state.update && this.state.update.graph && this.state.update.graph.length > 1) ?
                                                    <Chart chartType="Bar"
                                                           data={this.state.update.graph}
                                                           options={options}
                                                    /> :
                                                    <img alt={""} width={'50px'} src={loading} border="0"/>}


                                        </div>
                                    </div>

                                </div>
                                <div className="superviseBlockRight">
                                    <div className="superviseBlockRightTop">
                                        <div className="superviseTitleRightBlock"> Total (current month)</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.update ? MonitoringTools.cleanNumber(this.state.update.month) : null}</div>
                                    </div>
                                    <div className="superviseBlockRightBottom">
                                        <div className="superviseTitleRightBlock"> Total</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.update ? MonitoringTools.cleanNumber(this.state.update.global) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* Reported */}
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise">
                                <div className="superviseBlockLeft">
                                    <div style={{"height": "40%"}}>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">report</i>
                                            Issues
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock">{this.state.report ? this.state.report.today : null}</div>
                                    </div>
                                    <div style={{"height": "60%"}}>
                                        <div id="graph1" className="superviseGraph">
                                            {

                                                (this.state.report && this.state.report.graph && this.state.report.graph.length > 1) ?
                                                    <Chart chartType="Bar"
                                                           data={this.state.report.graph}
                                                           options={options}
                                                    /> :
                                                    <img alt={""} width={'50px'} src={loading} border="0"/>
                                            }


                                        </div>
                                    </div>

                                </div>
                                <div className="superviseBlockRight">
                                    <div className="superviseBlockRightTop">
                                        <div className="superviseTitleRightBlock"> Total (current month)</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.report ? this.state.report.month : null}</div>
                                    </div>
                                    <div className="superviseBlockRightBottom">
                                        <div className="superviseTitleRightBlock"> Remaining issues</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.report ? MonitoringTools.cleanNumber(this.state.report.global) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    {/* Validated */}
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise">
                                <div className="superviseBlockLeft">
                                    <div style={{"height": "40%"}}>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">check_box</i>
                                            AED Confirmed
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock">{this.state.validate ? MonitoringTools.cleanNumber(this.state.validate.today) : null}</div>
                                    </div>
                                    <div style={{"height": "60%"}}>
                                        <div id="graph1" className="superviseGraph">

                                            {
                                                (this.state.validate && this.state.validate.graph && this.state.validate.graph.length > 1) ?
                                                    <Chart chartType="Bar"
                                                           data={this.state.validate.graph}
                                                           options={options}
                                                    /> :
                                                    <img alt={""} width={'50px'} src={loading} border="0"/>
                                            }


                                        </div>
                                    </div>

                                </div>
                                <div className="superviseBlockRight">
                                    <div className="superviseBlockRightTop">
                                        <div className="superviseTitleRightBlock"> Total (current month)</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.validate ? MonitoringTools.cleanNumber(this.state.validate.month) : null}</div>
                                    </div>
                                    <div className="superviseBlockRightBottom">
                                        <div className="superviseTitleRightBlock"> Total</div>
                                        <div
                                            className="txtBlockmenuNb superviseNbRightBlock">{this.state.validate ? MonitoringTools.cleanNumber(this.state.validate.global) : null}</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                    {/* ThisWeek TOP */}
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{"height": "100%"}}>
                                <div style={{"width": "100%"}} className="superviseBlockLeft">
                                    <div>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">priority_high
                                            </i>
                                            Top Update (7 days)
                                        </div>
                                    </div>
                                    <div style={{"width": "100%"}}>
                                        <div style={{"width": "100%"}}>

                                            <table className="table table-striped table-hover table-condensed">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>First name</th>
                                                    <th>Last name</th>
                                                    <th>Identification</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                    (this.state.thisWeekUpdateByUser && this.state.thisWeekUpdateByUser.length === 0) ?

                                                        <tr>
                                                            <td colSpan="6" align="center">No data</td>
                                                        </tr>

                                                        :
                                                        (this.state.thisWeekUpdateByUser && this.state.thisWeekUpdateByUser.length > 0) ?

                                                            this.state.thisWeekUpdateByUser.map(function (object, i) {
                                                                return <PersonRow obj={object} key={i}/>
                                                            })

                                                            :
                                                            <tr>
                                                                <td colSpan="6" align="center"><img alt={""}
                                                                                                    width={'50px'}
                                                                                                    src={loading}
                                                                                                    border="0"/></td>
                                                            </tr>
                                                }


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                    {/* Global TOP */}
                    <div id="bk1" style={{"width": "50%"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{"height": "100%"}}>
                                <div style={{"width": "100%"}} className="superviseBlockLeft">
                                    <div>
                                        <div className="superviseTitle" style={{"color": "#059d49"}}>
                                            <i className="material-icons">public
                                            </i>
                                            Top Update (Global)
                                        </div>
                                    </div>
                                    <div style={{"width": "100%"}}>
                                        <div style={{"width": "100%"}}>

                                            <table className="table table-striped table-hover table-condensed">
                                                <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>#</th>
                                                    <th>First name</th>
                                                    <th>Last name</th>
                                                    <th>Identification</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {
                                                    (this.state.globalUpdateByUser && this.state.globalUpdateByUser.length === 0) ?

                                                        <tr>
                                                            <td colSpan="6" align="center">No data</td>
                                                        </tr>

                                                        :
                                                        (this.state.globalUpdateByUser && this.state.globalUpdateByUser.length > 0) ?

                                                            this.state.globalUpdateByUser.map(function (object, i) {
                                                                return <PersonRow obj={object} key={i}/>
                                                            })

                                                            :
                                                            <tr>
                                                                <td colSpan="6" align="center"><img alt={""}
                                                                                                    width={'50px'}
                                                                                                    src={loading}
                                                                                                    border="0"/></td>
                                                            </tr>
                                                }


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>


            </div>
        );
    }
}

const PersonRow = (props) => {
    //if (props.obj.firstname) console.log(props.obj.firstname.length)
    //console.log(props.keyObj)

    function handleClick(e) {
        console.log("clicked " + props.obj.identification);
        history.push('/monitoringUserDetail/' + props.obj.identification);

    }

    return (
        <tr>

            <td> {(props.obj.firstname && props.obj.firstname.length !== 0) ?
                <i className="material-icons" style={{"color": "#ea8f05"}}>perm_contact_calendar</i> : null}</td>
            <td>{props.obj.nb}</td>
            <td>{(props.obj.firstname && props.obj.firstname.length !== 0) ? props.obj.firstname : '-'}</td>
            <td>{(props.obj.lastname && props.obj.lastname.length !== 0) ? props.obj.lastname : '-'}</td>
            <td>{props.obj.identification}</td>
            <td><i style={{cursor: "pointer"}} className="material-icons"
                   onClick={() => handleClick(props.obj.identification)}> live_help </i></td>
        </tr>
    );
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(MonitoringAed);
export {connectedHomePage as MonitoringAed};