function cleanNumber(number) {

    let resultNumber = ''

    if (number) {

        if (number.toString().length > 3) {
            let div = Math.floor(number / 1000)
            let mod = number % 1000
            mod = mod + "";
            if (div >= 1) while (mod.length < 3) mod = "0" + mod;
            resultNumber = div + ' ' + mod
        }
        else resultNumber = number
    } else resultNumber = number

    return resultNumber
}


var MonitoringTools = {
    cleanNumber: cleanNumber,
}
module.exports = MonitoringTools