import React from 'react';
import {Router, Route} from 'react-router-dom';
import {connect} from 'react-redux';

import {history} from '../_helpers';
import {alertActions} from '../_actions';
import {PrivateRoute} from '../_components';

//Pages
import {Header} from '../_components/Pages';
import {HomePage} from '../_components/Pages';
import {LoginPage} from '../_components/Pages';
import {OverallPage} from "../_components/Pages";
import {MonitoringAed} from "../_components/Pages";
import {MonitoringFirstResponder} from "../_components/Pages";
import {MonitoringFirstResponderDetail} from "../_components/Pages";
import {MonitoringFirstResponderAlerts} from "../_components/Pages";
import {MonitoringFirstResponderAlertsMap} from "../_components/Pages";
import {MonitoringFirstResponderAnalytics} from "../_components/Pages";
import {MonitoringAppTest} from "../_components/Pages";
import {MonitoringFirstResponderByState} from "../_components/Pages";
import {MonitoringUserDetail} from "../_components/Pages";


class App extends React.Component {
    constructor(props) {
        super(props);

        const {dispatch} = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

    render() {
        const {alertMessage} = this.props;
        return (
            <div>
                <Header/>
                <div>
                    <div>
                        <div>
                            {alertMessage.message &&
                            <div className={`alert ${alertMessage.type}`}>{alertMessage.message}</div>
                            }
                            <Router history={history}>
                                <div>
                                    <PrivateRoute exact path="/" component={HomePage}/>
                                    <PrivateRoute exact path="/overall" component={OverallPage}/>
                                    <PrivateRoute exact path="/monitoringAed" component={MonitoringAed}/>
                                    <PrivateRoute exact path="/monitoringFirstResponder" component={MonitoringFirstResponder}/>
                                    <PrivateRoute exact path="/monitoringFirstResponderDetail" component={MonitoringFirstResponderDetail}/>
                                    <PrivateRoute exact path="/monitoringFirstResponderByState" component={MonitoringFirstResponderByState}/>
                                    <PrivateRoute exact path="/monitoringFirstResponderAlerts" component={MonitoringFirstResponderAlerts}/>
                                    <PrivateRoute exact path="/monitoringFirstResponderAlertsMap" component={MonitoringFirstResponderAlertsMap}/>
                                    <PrivateRoute exact path="/monitoringFirstResponderAnalytics" component={MonitoringFirstResponderAnalytics}/>
                                    <PrivateRoute exact path="/monitoringAppTest" component={MonitoringAppTest}/>
                                    <PrivateRoute path="/monitoringUserDetail" component={MonitoringUserDetail}/>
                                    <Route path="/login" component={LoginPage}/>
                                </div>
                            </Router>
                        </div> 
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    const {alertMessage} = state;
    return {
        alertMessage
    };
}

const connectedApp = connect(mapStateToProps)(App);
export {connectedApp as App};