import React from 'react';
import {connect} from 'react-redux';
import config from '../../config.js'

import loading from '../../Images/loading.gif';
import '../../Styles/MonitoringFirstResponder.css';

class MonitoringFirstResponderDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: null,
            myToken: localStorage.getItem('token'),
            timeout: '',
            lastSort: {
                id: '',
                type: 1
            }
        }

        this.sortDetails = this.sortDetails.bind(this)


    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            60000 * 60 * 12 // 60000 = 1 minutes
        );

        this.tick()
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.detail()
    }


    detail() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/detail`, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()

                return jsonObj
            })
            .then(response => {


                return this.setState({detail: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }

    sortDetails(id) {

        function compareDESC(a, b) {
            if (a[id] > b[id])
                return -1;
            if (a[id] < b[id])
                return 1;
            return 0;
        }

        function compareASC(a, b) {
            if (a[id] < b[id])
                return -1;
            if (a[id] > b[id])
                return 1;
            return 0;
        }

        this.state.lastSort.type = this.state.lastSort.type * -1

        if (this.state.lastSort.id === id) {


            if (this.state.lastSort.type === 1) this.setState({detail: this.state.detail.sort(compareDESC)})
            else this.setState({detail: this.state.detail.sort(compareASC)})
        }
        else {

            let array = this.state.detail.sort(compareASC)

            this.setState({detail: array})
            this.state.lastSort.id = id

        }



    };

    handleSort(e, id) {
        e.preventDefault()
        this.sortDetails(id)
    };

    render() {

        return (
            <div id="layout-content" className="layout-content-wrapper">

                <div id="mainDashboard">

                    {/* ThisWeek TOP */}
                    <div id="bk1" style={{"width": "100%", "marginTop": "60px"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{"height": "100%"}}>
                                <div style={{"width": "100%"}} className="superviseBlockLeft">
                                    <div>
                                        <div className="superviseTitle">
                                            <i className="material-icons">priority_high
                                            </i>
                                            Site/Citizen Responder Details
                                        </div>
                                    </div>
                                    <div style={{"width": "100%"}}>
                                        <div className="table-responsive" style={{"width": "100%"}}>

                                            <table className="table table-striped table-hover table-condensed">
                                                <thead>
                                                <tr>
                                                    <th>
                                                        <a href="#" onClick={(e) => this.handleSort(e, 'name')}>
                                                            Site
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'NbAlertThisMonth')}>
                                                            # Alert (current month)
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'craQ')}>
                                                            CRA (Q/Q-1)
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'NbAlert')}>
                                                            # Alert
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'nbAlertWithOneBS')}>
                                                            # Alert +
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'nbCitizenResponderPositifTotal')}>
                                                            # CR OK
                                                        </a>
                                                    </th>
                                                    <th>
                                                        <a href="#"
                                                           onClick={(e) => this.handleSort(e, 'nbCitizenResponderOnSiteTotal')}>
                                                            # CR On Site
                                                        </a>
                                                    </th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    (this.state.detail && this.state.detail.length === 0) ?

                                                        <tr>
                                                            <td colSpan="7" align="center">No data</td>
                                                        </tr>

                                                        :
                                                        (this.state.detail && this.state.detail.length > 0) ?

                                                            this.state.detail.map(function (object, i) {
                                                                console.log(object)     
                                                                return <PersonRow obj={object} key={i}/>
                                                            })

                                                            :
                                                            <tr>
                                                                <td colSpan="7" align="center"><img alt={""}
                                                                                                    width={'50px'}
                                                                                                    src={loading}
                                                                                                    border="0"/></td>
                                                            </tr>
                                                }


                                                </tbody>
                                            </table>


                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>


                </div>


            </div>
        );
    }
}

const PersonRow = (props) => {

    //console.log(props.keyObj)
    return (
        <tr>
            <td>{props.obj.name}</td>
            <td>{props.obj.NbAlertThisMonth}</td>
            <td>{props.obj.craQ+' / '+props.obj.craQ_1}</td>
            <td>{props.obj.NbAlert}</td>
            <td>{props.obj.nbAlertWithOneBS}</td>
            <td>{props.obj.nbCitizenResponderPositifTotal}</td>
            <td>{props.obj.nbCitizenResponderOnSiteTotal}</td>

        </tr>
    );
}

function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(MonitoringFirstResponderDetail);
export {connectedHomePage as MonitoringFirstResponderDetail};