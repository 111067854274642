import React from 'react';
import {connect} from 'react-redux';
import config from '../../config.js'

import '../../Styles/MonitoringFirstResponder.css';

class MonitoringAppTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unitStatus1: null,
            unitStatus2: null,
            unitStatus3: null,
            myToken: localStorage.getItem('token'),
        }


    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            60000 * 60 * 24
        );

        this.tick()
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.GetUnitStatus1()
        this.GetUnitStatus2()
        this.GetUnitStatus3()
    }

    GetUnitStatus1() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/getUnitStatus?phoneNumber=101010101`, settings)
            .then(this.handleResponse)
            .then(response => {
                return response.json()
            })
            .then(response => {

                //console.log(response.data)
                return this.setState({unitStatus1: response.data})

            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })


    }

    GetUnitStatus2() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/getUnitStatus?phoneNumber=101010102`, settings)
            .then(this.handleResponse)
            .then(response => {
                return response.json()
            })
            .then(response => {

                //console.log(response.data)
                return this.setState({unitStatus2: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })


    }

    GetUnitStatus3() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),

        }


        fetch(`${config.APIURL}/monitoring/firstResponder/getUnitStatus?phoneNumber=101010103`, settings)
            .then(this.handleResponse)
            .then(response => {
                return response.json()
            })
            .then(response => {

                //console.log(response.data)
                return this.setState({unitStatus3: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })


    }

    handleResponse(response) {
        if (response.status === 401) {
            // auto logout if 401 response returned from api
            localStorage.removeItem('token');
            window.location.reload(true);

            return null
        }
        else return response

    }


    fotmatDate(date) {

        let clearString = function (string) {
            if (string.toString().length < 2) string = '0' + string
            return string
        }

        let linuxDate = new Date(Date.parse(date))

        let newDate = clearString(linuxDate.getUTCFullYear()) + '/' + clearString((linuxDate.getMonth() + 1)) + '/' + clearString(linuxDate.getDate())
        let newTime = clearString(linuxDate.getHours()) + ':' + clearString(linuxDate.getMinutes()) + ':' + clearString(linuxDate.getSeconds())

        return newDate + ' - ' + newTime
    }

    render() {

        return (
            <div id="layout-content" className="layout-content-wrapper">

                <div id="mainDashboard">

                    {/* APP Test */}
                    <div id="bk1" style={{"width": "100%","marginTop": "60px"}}>
                        <div className="bBlock blockContentSuperviseMain">
                            <div className="blockContentSupervise" style={{height: '100%', margin: '5px'}}>
                                <div className="superviseTitle" style={{float: 'center', margin: '5px'}}>


                                    <i className="material-icons">replay</i>
                                    Alert {this.state.unitStatus1 ?
                                    this.state.unitStatus1.idAlert
                                    :
                                    this.state.unitStatus2 ?
                                        this.state.unitStatus2.idAlert
                                        :
                                        this.state.unitStatus3 ?
                                            this.state.unitStatus3.idAlert
                                            :
                                            null} -
                                    {this.state.unitStatus1 && this.state.unitStatus1.cdate ?
                                        this.fotmatDate(this.state.unitStatus1.cdate)
                                        :
                                        this.state.unitStatus2 && this.state.unitStatus2.cdate ?
                                            this.fotmatDate(this.state.unitStatus2.cdate)
                                            :
                                            this.state.unitStatus3 && this.state.unitStatus3.cdate ?
                                                this.fotmatDate(this.state.unitStatus3.cdate)
                                                :
                                                null
                                    }
                                </div>


                            </div>
                            <div className="blockContentSupervise" style={{height: '100%'}}>

                                {/* UnitTest1 [0101010101] */}
                                <div className={"blockMonitoring"}
                                     style={{float: 'left', width: '50%', height: '100%'}}>
                                    <div style={{height: "40%"}}>
                                        <div className="superviseTitle">
                                            <i className="material-icons">android
                                            </i>
                                            UnitTest1 [0101010101]
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock"
                                            style={{'marginTop': '0px'}}>
                                            {this.state.unitStatus1 && this.state.unitStatus1.notificationAlert === true && this.state.unitStatus1.notificationMap === true ?
                                                <i className="material-icons md-48" style={{color: 'green'}}>
                                                    done_outline
                                                </i>
                                                : <i className="material-icons md-48" style={{color: 'red'}}>
                                                    error
                                                </i>
                                            }
                                        </div>
                                        {this.state.unitStatus1 && this.state.unitStatus1.notificationAlert !== true ?
                                            <div><i className="material-icons md-18" style={{color: 'red'}}>
                                                bug_report
                                            </i>
                                                Notification
                                            </div>
                                            : null
                                        }
                                        {this.state.unitStatus1 && this.state.unitStatus1.notificationMap !== true ?
                                            <div><i className="material-icons md-18" style={{color: 'red'}}>
                                                bug_report
                                            </i>
                                                Map
                                            </div>
                                            : null
                                        }
                                    </div>

                                </div>
                                {/* UnitTest1 [0101010102] */}
                                <div className={"blockMonitoring"}
                                     style={{float: 'right', width: '50%', height: '100%'}}>
                                    <div style={{height: "40%"}}>
                                        <div className="superviseTitle">
                                            <i className="material-icons">android
                                            </i>
                                            UnitTest2 [0101010102]
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock"
                                            style={{marginTop: '0px'}}>
                                            {this.state.unitStatus2 && this.state.unitStatus2.notificationAlert === true && this.state.unitStatus2.notificationMap === true ?
                                                <i className="material-icons md-48" style={{color: 'green'}}>
                                                    done_outline
                                                </i>
                                                : <i className="material-icons md-48" style={{color: 'red'}}>
                                                    error
                                                </i>
                                            }
                                        </div>

                                        {this.state.unitStatus2 && this.state.unitStatus2.notificationAlert !== true ?
                                            <div><i className="material-icons md-18" style={{color: 'red'}}>
                                                bug_report
                                            </i>
                                                Notification
                                            </div>
                                            : null
                                        }
                                        {this.state.unitStatus2 && this.state.unitStatus2.notificationMap !== true ?
                                            <div><i className="material-icons md-18" style={{color: 'red'}}>
                                                bug_report
                                            </i>
                                                Map
                                            </div>
                                            : null
                                        }

                                    </div>


                                </div>
                                {/* UnitTest1 [0101010103] */}

                                <div className={"blockMonitoring"}
                                     style={{float: 'left', width: '50%', height: '100%'}}>
                                    <div style={{height: "40%"}}>
                                        <div className="superviseTitle">
                                            <i className="material-icons">android
                                            </i>
                                            UnitTest3 [0101010103]
                                        </div>
                                        <div
                                            className="txtBlockmenuNb superviseNbLeftBlock"
                                            style={{'marginTop': '0px'}}>
                                            {this.state.unitStatus3 && this.state.unitStatus3.notificationAlert === true && this.state.unitStatus3.notificationMap === true ?
                                                <i className="material-icons md-48" style={{color: 'green'}}>
                                                    done_outline
                                                </i>
                                                : <i className="material-icons md-48" style={{color: 'red'}}>
                                                    error
                                                </i>
                                            }
                                        </div>

                                        {this.state.unitStatus3 && this.state.unitStatus3.notificationAlert !== true ?
                                            <div><i className="material-icons md-18" style={{color: 'red'}}>
                                                bug_report
                                            </i>
                                                Notification
                                            </div>
                                            : null
                                        }
                                        {this.state.unitStatus3 && this.state.unitStatus3.notificationMap !== true ?
                                            <div><i className="material-icons md-18" style={{color: 'red'}}>
                                                bug_report
                                            </i>
                                                Map
                                            </div>
                                            : null
                                        }

                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>


                </div>


            </div>
        );
    }
}


function mapStateToProps(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user,
        users
    };
}

const connectedHomePage = connect(mapStateToProps)(MonitoringAppTest);
export {connectedHomePage as MonitoringAppTest};