import React from 'react';
import {connect} from 'react-redux';

import config from '../../config.js'

export default class AlertCounter extends React.Component  {
  
    static propTypes = {
    };

    constructor(props) {
        super(props);
        this.state = {
            detail: 0,
            myToken: localStorage.getItem('token'),
            idAlert: props.idAlert
        }
        console.log(props)
    }
    componentDidMount() {
        this.counter()
    }

    counter() {

        let settings = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'JWT ' + this.state.myToken,
            }),
        }

        fetch(`${config.APIURL}/monitoring/firstResponder/alertsCounter?idAlert=` + this.state.idAlert, settings)
            .then(this.handleResponse)
            .then(response => {
                let jsonObj = response.json()
                return jsonObj
            })
            .then(response => {
                return this.setState({detail: response.data})
            })
            .catch(error => {
                console.log(error.toString())
                return this.setState({error})
            })

    }


  render() {
    return(
        <>
            <td>{this.state.detail.accept}</td>
            <td>{this.state.detail.call}</td>
       </>
    )
  }
}