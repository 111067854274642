import React, {Component} from 'react';
import '../../Styles/Header.css';


import logoAEDMAP from '../../Images/logoAEDMAP.png';
import {connect} from "react-redux";
import {alertActions} from "../../_actions";
import {history} from "../../_helpers";


class Header extends Component {
    constructor(props) {
        super(props);

        const {dispatch} = this.props;
        history.listen((location, action) => {
            // clear alert on location change
            dispatch(alertActions.clear());
        });
    }

    render() {


        return (

            <div id="header" style={{width: '100%', position: 'fixed'}}>
                <div className="holder">
                    <div id="distributorLogo"><a href="\"><img alt={""} src={logoAEDMAP} border="0"/></a></div>
                </div>
            </div>
        )
            ;
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}

const connectedApp = connect(mapStateToProps)(Header);
export {connectedApp as Header};