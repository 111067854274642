import config from '../config.js'

export const userService = {
    login,
    logout,
    info,
};

function login(username, password) {

    let details = {
        username: username,//'accGlobalAdmin',
        password: password,//'BvtDj#TuzAtfqqEf'
    }


    var formBody = [];
    for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let settings = {
        method: 'POST',
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formBody
    }
    return fetch(`${config.APIURL}/services/signin/`, settings)
        .then(handleResponse)
        .then(response => {

            let token = 'tmp'
            if (response.error === false) {
                console.log('Token')
                console.log(response.data.token)
                localStorage.setItem('token', response.data.token);
                token = response.data.token
            }

            return token;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
}

function info() {
    return 'hello';
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}